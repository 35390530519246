<template>
	<div @click.ctrl.exact="playerPlayPause++" class="fixed w-full display">

		<Navbar>
			<!-- <p class="mt-1 text-sm font-semibold text-center cursor-pointer text-slate-100">
				{{ $store.state.portalname }}
			</p> -->
		</Navbar>
		<div class="absolute flex-col w-full overflow-x-hidden">
			<div class="flex flex-row justify-between w-full">

				<div>
					<PlayerDisplay v-if="this.$store.state.authCompleted" :key="playerKey" :audio_ID="audio_ID"
						:storybookStyle="storybookStyle" :playerPlayPause="playerPlayPause"
						@rerenderPlayer="playerKey++" @playingBegun="playingBegunfunc()" />
				</div>
				<div class="flex flex-row ml-[105px] mr-[105px] w-full justify-end">
					<div class="flex flex-col items-center w-full">
						<span v-if="$store.state.consoles[0]" :key="$store.state.consoles[0]"
							class="w-full box-border px-[.5vw]">
							<!-- tell the column which audio ID we are working with, which interpretations to put in the dropdown menu for viewing, which interpretations are currently being viewed,
      and the id of the interpretation to be displayed in this column in the browser window.  The interpretation component can use events
      to tell the current component to delete this interpretation column and add a new one for a different interpretation ID. -->
							<SingleInterpretationDisplay :audio_id="audio_ID" :interpretationsList="interpretationsList"
								:styleoption="storybookStyle" :formerInterpretationsList="formerInterpretationsList"
								:phraseLength="phraseLength" :interpretation_id="$store.state.consoles[0]"
								:playingBeginning="playingBeginning"
								@returnFormerInterpretation="returnFormerInterpretation($event)"
								@displayInterpretationID="displayInterpretationID($event)"
								@permanentlydestroy="permanentlydestroy($event)"
								@exchangeInterpretations="exchangeInterpretationsfunction($event)"
								@updateTitleLanguage="updateTitleLanguagefunc($event)" />
						</span>
						<!-- the following component can tell the current component to add a new column for an interpretation that it just created,
      or to add a new column for an interpretation that has previously been created. -->



						<div class="flex flex-col  w-full px-[.7vw]">
							<!-- <p class="justify-start flex pt-[1vh] pb-[5vh] font-medium w-full cursor-pointer endtext ">Attribution:
							</p> -->

							<p class=" pb-[5vh] justify-center text-center w-full font-medium cursor-pointer endtext flex "
								@click="visitDito()">Click-Text
								created by Yakalo Collective (patent pending)
							</p>


						</div>
					</div>

					<div v-if="$store.state.options" class="pt-[10.5vh]  flex w-[80px]  flex-col mr-[1vw]
				right-0 fixed">
						<button v-if="isStudy" class=" clear z-50 mt-[1vh]" @click="toggleSwitch()">
							Listen
						</button>

						<button v-else class=" cursor-default important  mt-[1vh]">
							Listen
						</button>

						<button v-if="!isStudy" class=" clear z-50 mt-[1vh]" @click="toggleSwitch()">
							Learn
						</button>

						<button v-else class=" cursor-default  important mt-[1vh]">
							Learn
						</button>
						<br>


						<!-- This is if you want only two options, Words and Phrases -->
						<button v-if="phraseLength >= 80" class="clear mt-[1vh]" @click="shorterPhrases()">
							Words
						</button>

						<button v-else class="  cursor-default important mt-[1vh]">
							Words
						</button>

						<button v-if="phraseLength < 80" class=" clear mt-[1vh]" @click="longerPhrases()">
							Phrases
						</button>

						<button v-else class=" cursor-default important mt-[1vh]">
							Phrases
						</button>



						<!-- This is if you want a range of lengths -->
						<!-- <button v-if="phraseLength <= 120" class="clear mt-[1vh]" @click="longerPhrases()">
							Longer
						</button>

						<button v-else class="  cursor-default important mt-[1vh]">
							Longer
						</button>

						<button v-if="phraseLength >= 40" class=" clear mt-[1vh]" @click="shorterPhrases()">
							Shorter
						</button>

						<button v-else class=" cursor-default important mt-[1vh]">
							Shorter
						</button>


						<div class="flex justify-center">
							<span class="text-xs"
								:class="{ 'mt-[1.5vh]': $store.state.promptsObject.name == 'བོད་ཡིག' }">>{{
									phraseLength
								}}cs</span>
						</div> -->
					</div>
				</div>

			</div>


		</div>
	</div>
</template>

<script>
import { useRoute } from 'vue-router';
import Navbar from "@/displaycomponents/NavbarDisplay.vue";
import PlayerDisplay from "@/displaycomponents/PlayerDisplay.vue";
import SingleInterpretationDisplay from "@/displaycomponents/SingleInterpretationDisplay.vue";
// import AddInterpretationViewer from "@/components/AddInterpretationViewer.vue";
// import AddInterpretationModal from "@/components/AddInterpretationModal.vue";
// import UploadIntModal from "@/components/UploadIntModal.vue";
import { getIdToken } from "firebase/auth";

export default {
	name: "Display",
	components: {
		Navbar,
		PlayerDisplay,
		SingleInterpretationDisplay,
		// AddInterpretationViewer,
		// AddInterpretationModal,
		// UploadIntModal,
	},
	data: () => {
		return {
			playingBeginning: 0,
			phraseLengthChanged: false, // changes to true when somebody clicks Longer or Shorter button
			playerKey: 0, // helper; when it changes, reload Player Vertical
			interpretationsList: [], // the list of interpretations that can be selected from the dropdown menu (does not include interpretations currently being viewed by this user in this browser window)
			formerInterpretationsList: [], // the list of interpretations currently being viewed by this user in this browser window
			showAddInterpretationModal: false,
			showUploadIntModal: false,
			playerPlayPause: 0, // when this changes, play or pause Player Vertical
			route: {},
			intArray: [],
			storybookStyle: "Viewer",
			isStudy: false,
			phraseLength: 100,
		};
	},
	props: {
		audio_ID: "",
		interpretation_id: "",
		// title: "",
	},
	computed: {
		// consolespace() {
		// 	return this.$store.state.consoleswidth - 210;
		// },
	},

	watch: {
		isStudy: function () {

			if (this.isStudy && this.phraseLengthChanged == 0) { this.phraseLength = 40 }// first time changing to Learn, decrease the phrase length all the way.
		},

		"$store.state.authCompleted": function () {
			if (this.$store.state.authCompleted === true) {
				this.getInterpretations();
			}
		},

		"$store.state.consoleschanged": function () {
			if (this.$store.state.promptsObject.code != "en") {
				const url = new URL(location)
				url.searchParams.set("view", this.$store.state.promptsObject.code)
				let openparam = ""
				for (let i = 0; i < this.$store.state.consoles.length; i++) {
					openparam = openparam + this.$store.state.consoles[i]
				}
				if (openparam.length > 0) { url.searchParams.set("open", openparam) }
				else { url.searchParams.delete("open") }
				history.replaceState(history.state, '', url)
			}
			else {
				const url = new URL(location)
				url.searchParams.delete("view")
				let openparam = ""
				for (let i = 0; i < this.$store.state.consoles.length; i++) {
					openparam = openparam + this.$store.state.consoles[i]
				}
				if (openparam.length > 0) { url.searchParams.set("open", openparam) }
				else { url.searchParams.delete("open") }
				history.replaceState(history.state, '', url)
			}


		},

	},

	created() {
		window.addEventListener("resize", this.myEventHandler);
	},

	mounted() {
		this.$store.commit("updateRouteName", "Display")




		// console.log(this.$store.state.promptsObject)
		this.route = useRoute();
		// console.log(this.route)
		if (this.route.query.open) {
			this.intArray = this.route.query.open.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&").match(new RegExp(/.{11}/g))
		}


		// if (this.$store.state.promptsObject.code != "en") {
		// 	const url = new URL(location)
		// 	url.searchParams.set("view", this.$store.state.promptsObject.code)
		// 	history.replaceState(history.state, '', url)
		// }
		// else {
		// 	// console.log(this.$store.state.promptsObject.code)
		// 	const url = new URL(location)
		// 	// console.log(url)
		// 	console.log("FOUR")
		// 	url.searchParams.delete("view")
		// 	history.replaceState(history.state, '', url)
		// }

		// console.log(this.intArray);

		document.title =
			"Dito - " + window.location.hostname.split(".")[0];
		if (this.$store.state.authCompleted === true) {
			this.getInterpretations();
		}
		// console.log(this.$route.params)
	},
	unmounted() {
		this.$store.commit("updateAudioDuration", 0);
		window.removeEventListener("resize", this.myEventHandler);
	},

	beforeUnmount() {
		this.$store.commit("clearConsoles");
		this.$store.commit("updateAudioTime", 0)
		this.interpretationsList.length = 0;
		this.formerInterpretationsList.length = 0;
	},

	methods: {

		visitDito() {
			// const routeData = this.$router.resolve({
			// 	name: "Home",
			// });
			window.open("https://www.yakalo.co/", '_blank');
		},

		playingBegunfunc() {
			this.playingBeginning++
		},

		longerPhrases() {
			// This is if you want only two options, Words and Phrases
			this.phraseLength = 85

			// This is if you want a range of lengths
			// this.phraseLength = parseInt(this.phraseLength) + 20

			this.phraseLengthChanged = true

		},

		shorterPhrases() {
			// This is if you want only two options, Words and Phrases
			this.phraseLength = 40

			// This is if you want a range of lengths
			// this.phraseLength = parseInt(this.phraseLength) - 20

			this.phraseLengthChanged = true

		},

		toggleSwitch() {
			this.isStudy = !this.isStudy
			if (this.isStudy) { this.storybookStyle = "Studio" }
			else { this.storybookStyle = "Viewer" }
			if (this.storybookStyle == "Studio") {
				this.$store.commit("updatePrompterID", this.interpretation_id);
			} else if (
				this.storybookStyle == "Viewer" &&
				this.$store.state.prompterID == this.interpretation_id
			) {
				this.$store.commit("removePrompterID");
			}
		},

		async getInterpretations() {
			//fetch the interpretations the logged-in user has access to for this audio file

			if (this.$store.state.user) {
				// REFRESH ID TOKEN FIRST AND WAIT FOR IT
				await getIdToken(this.$store.state.user)
					.then((idToken) => {
						this.$store.commit("SetIdToken", idToken);
						// console.log(this.$store.state.idToken)
					})
					.catch((error) => {
						// An error happened.
						console.log("Oops. " + error.code + ": " + error.message);
					});
			}

			const apiUrl =
				process.env.VUE_APP_api_URL +
				"interpretations/audio/" +
				this.audio_ID +
				"/";
			fetch(apiUrl, {
				method: "GET",

				headers: {
					"Content-Type": "application/json",

					Authorization: this.$store.state.idToken,
				},
			})
				.then((response) => response.json())
				.then((data) => {

					if (data["interpretations"] != "none") {
						this.interpretationsList = data["interpretations"];
						let mapped = this.interpretationsList.map(
							(item) => item.id
						)

						this.intArray.forEach(queryint => {
							if (mapped.indexOf(queryint) != -1) {
								this.displayInterpretationID(queryint);
								// change this to router push???!!!!
							}

						});

						if (this.$store.state.consoles.length == 0) {
							let temp_id = this.interpretationsList[0].id;
							this.displayInterpretationID(temp_id);
						}
					}


				})
				.catch((error) => console.error("Error:", error));

			this.$store.commit(
				"updateConsolesWidth",
				document.documentElement.clientWidth
			);
			this.$store.commit(
				"updateConsolesHeight",
				document.documentElement.clientHeight
			);
		},

		myEventHandler(e) {
			this.$store.commit(
				"updateConsolesWidth",
				document.documentElement.clientWidth
			);
			this.$store.commit(
				"updateConsolesHeight",
				document.documentElement.clientHeight
			);
		},

		permanentlydestroy(oldInterpretation) {
			// make an array of the ID's of interpretations currently being viewed
			// console.log(oldInterpretation);
			let mappedoldIDsArray = this.formerInterpretationsList.map(
				(item) => item.id
			);

			// find the index # of the ID to be returned to the dropdown menu (and removed from the viewer)
			let indexofold = mappedoldIDsArray.indexOf(oldInterpretation);
			if (indexofold > -1) {
				// ... and remove it from the list of interpretations currently being viewed
				this.formerInterpretationsList.splice(indexofold, 1); // 2nd parameter means remove one item only
			}

			// tell the Vuex store to remove the ID number of the interpretation in question from the list of interpretions that currently need columns in the browser window
			this.$store.commit("deleteConsole", oldInterpretation);
		},

		exchangeInterpretationsfunction({ newID, formerinterpretation }) {
			let mappedoldIDsArray = this.formerInterpretationsList.map(
				(item) => item.id
			);

			let indexofold = mappedoldIDsArray.indexOf(formerinterpretation);
			if (indexofold > -1) {
				// if the index # is successfully identified, then add the whole interpretation object to the list of interpretations for the dropdown menu...
				this.interpretationsList.unshift(
					this.formerInterpretationsList[indexofold]
				);
				// ... and remove it from the list of interpretations currently being viewed
				this.formerInterpretationsList.splice(indexofold, 1); // 2nd parameter means remove one item only
			}

			let mappedIDsArray = this.interpretationsList.map((item) => item.id);
			let index = mappedIDsArray.indexOf(newID);
			if (index > -1) {
				// if the index # is successfully identified, then add the whole interpretation object to the list of interpretations for the browser window...

				this.formerInterpretationsList.push(this.interpretationsList[index]);
				// ... and remove it from the list of interpretations currently in the Dropdown menu
				this.interpretationsList.splice(index, 1); // 2nd parameter means remove one item only
			}
			let tempObject = { newID, formerinterpretation }
			this.$store.commit("exchangeConsole", tempObject)
			if (this.$store.state.prompterID == formerinterpretation) {
				this.$store.commit("removePrompterID");
			}

		},

		// move an interpretation from a column in the browser window to the dropdown menu
		returnFormerInterpretation(oldInterpretation) {
			// make an array of the ID's of interpretations currently being viewed
			// console.log(oldInterpretation);
			let mappedoldIDsArray = this.formerInterpretationsList.map(
				(item) => item.id
			);

			// find the index # of the ID to be returned to the dropdown menu (and removed from the viewer)
			let indexofold = mappedoldIDsArray.indexOf(oldInterpretation);
			if (indexofold > -1) {
				// if the index # is successfully identified, then add the whole interpretation object to the list of interpretations for the dropdown menu...
				this.interpretationsList.unshift(
					this.formerInterpretationsList[indexofold]
				);
				// ... and remove it from the list of interpretations currently being viewed
				this.formerInterpretationsList.splice(indexofold, 1); // 2nd parameter means remove one item only
			}

			// tell the Vuex store to remove the ID number of the interpretation in question from the list of interpretions that currently need columns in the browser window
			this.$store.commit("deleteConsole", oldInterpretation);
			if (this.$store.state.prompterID == oldInterpretation) {
				this.$store.commit("removePrompterID");
			}
		},

		displayInterpretationID(newID) {
			// make an array of the ID's of interpretations currently in the Dropdown menu
			let mappedIDsArray = this.interpretationsList.map((item) => item.id);

			// find the index # of the interpretation ID that we want to add into a column in the browser window (and removed from the Dropdown menu)
			let index = mappedIDsArray.indexOf(newID);
			if (index > -1) {
				// if the index # is successfully identified, then add the whole interpretation object to the list of interpretations for the browser window...

				this.formerInterpretationsList.push(this.interpretationsList[index]);
				// ... and remove it from the list of interpretations currently in the Dropdown menu
				this.interpretationsList.splice(index, 1); // 2nd parameter means remove one item only
				// console.log(this.interpretationsList[index])
			}

			// tell the Vuex store to add the ID number of the interpretation in question to the list of interpretions that currently need columns in the browser window
			this.$store.commit("addConsolesCount", newID);
		},

		// add a whole interpretation object (which was emitted by the child component that just created it and also created a new column for it) to the list of interpretations that are being viewed in the browser window
		addCreatedInterpretation(interpretation) {
			this.formerInterpretationsList.push(interpretation);
			this.$store.commit("addConsolesCount", interpretation.id);
		},

		// toggleStorybookStylefunc(newStorybookStyle) {
		// 	this.storybookStyle = newStorybookStyle
		// },

		updateTitleLanguagefunc({ id, title, language }) {
			// console.log("inside")
			let mappedoldIDsArray = this.formerInterpretationsList.map(
				(item) => item.id
			);
			// find the index # of the ID to be returned to the dropdown menu (and removed from the viewer)
			let indexofold = mappedoldIDsArray.indexOf(id);
			if (indexofold > -1) {
				// ... and remove it from the list of interpretations currently being viewed
				// console.log(this.formerInterpretationsList[indexofold])
				this.formerInterpretationsList[indexofold].title = title
				this.formerInterpretationsList[indexofold].language_name = language
				console.log("updating " + this.formerInterpretationsList[indexofold].version + " to " + (this.formerInterpretationsList[indexofold].version + 1))
				this.formerInterpretationsList[indexofold].version = this.formerInterpretationsList[indexofold].version + 1
				console.log("now it is " + this.formerInterpretationsList[indexofold].version)
			}
		},

		toggleUploadIntModal() {
			this.showUploadIntModal = !this.showAddInterpretationModal;
		},
		closeUploadIntModal() {
			this.showUploadIntModal = false;
		},
		toggleInterpretationModal() {
			this.showAddInterpretationModal = !this.showAddInterpretationModal;
		},
		closeInterpretationModal() {
			this.showAddInterpretationModal = false;
		},
	},
};
</script>

<style scoped>
.display {
	/* -ms-overflow-style: none; */
	/* for Internet Explorer, Edge */
	/* scrollbar-width: none; */
	/* for Firefox */
	overflow-y: scroll;
	/* overscroll-behavior: none;
	scroll-snap-type: y mandatory;
	scroll-snap-align: start; */
}

/* Base scrollbar styles */
::-webkit-scrollbar {
    width: 14px; /* Updated width */
    height: 14px; /* For horizontal scrollbar */
}

/* Scrollbar track (background of the scrollbar) */
::-webkit-scrollbar-track {
    background: #f0f0f0; /* Light grey background to contrast with text */
    border-radius: 7px; /* Adjusted for a proportional look */
}

/* Scrollbar thumb (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
    background-color: #ede9fe; 
    border-radius: 7px; /* Rounded edges */
    border: 4px solid #f0f0f0; /* Light border for contrast */
}

/* Scrollbar thumb on hover (slightly darker when hovering) */
::-webkit-scrollbar-thumb:hover {
    background-color: #be185d; /* pink 700 */
}

/* Scrollbar corner (bottom right corner, if both scrollbars are active) */
::-webkit-scrollbar-corner {
    background: #f0f0f0;
}



.clear {
	touch-action: manipulation;
	color: #15803d;
	font-weight: 700;
	/* border-left: 5px solid #15803d; Add a green vertical bar */
	padding-left: 15px;
	/* Add some space between the button text and the bar */

	align-self: flex-start;
}

.clear:hover {
	color: #0369a1;
	/*#0369a1 064e3b 1d4ed8*/
	text-decoration-line: underline;
	/* border-left: 5px solid #0369a1; Add a green vertical bar */
	padding-left: 15px;
	font-weight: 700;
	/* Add some space between the button text and the bar */

	align-self: flex-start;
}

.clear:active {
	transform: translateY(.3px);
	font-weight: 700;
	/* Gives a pressed-down effect */
}



.important {
	touch-action: manipulation;
	color: #1d4ed8;
	font-weight: 700;
	/* border-left: 5px solid #a1a1aa; Add a green vertical bar */

	border-left: 5px solid #1d4ed8;
	/* Add a green vertical bar */
	padding-left: 10px;
	/* Add some space between the button text and the bar */

	align-self: flex-start;
}

.endtext {
	font-family: 'Lora', serif;

}
</style>
