<template>
	<div class="flex w-full font-medium rounded beautiful bg-zinc-50 text-zinc-600">
		<div class="flex-col w-full">
			<div
				class="flex fixed pr-[31.15vw] z-40 text-[3rem] -mt-[3.5vh] text-yellow-400 justify-end  w-full clear  ">
				<button class="hover:text-yellow-600" @click="toggleOptions()">&hellip;</button>
			</div>
			<p class="flex italic mt-[3.5vh] font-semibold text-rose-900">Click the best match for this audio:</p>
			<div class="flex pl-[1.6vw] w-full  pt-[6.8vh] border-gray-300 rounded">

				<div class="-mt-[2.5vh] pb-[2vh]" v-if="substringArray.length > 0">
					<span class="flex flex-col">
						<p v-if="phrasechoicesArray[0]" class="pb-3 " style="display:inline"><span class="cursor-pointer"
								@click="chooseanswer(phrasechoicesArray[0])">
								1. {{ phrasechoicesArray[0] }}</span></p>
						<p v-if="phrasechoicesArray[1]" class="pb-3 " style="display:inline"><span class="cursor-pointer"
								@click="chooseanswer(phrasechoicesArray[1])">
								2. {{ phrasechoicesArray[1] }}</span></p>
						<p v-if="phrasechoicesArray[2]" class="pb-3 " style="display:inline"><span class="cursor-pointer"
								@click="chooseanswer(phrasechoicesArray[2])">
								3. {{ phrasechoicesArray[2] }}</span></p>
						<p v-if="phrasechoicesArray[3]" class="pb-3 " style="display:inline"><span class="cursor-pointer"
								@click="chooseanswer(phrasechoicesArray[3])">
								4. {{ phrasechoicesArray[3] }}</span></p>
					</span>
				</div>
			</div>

			<!-- <textarea class="w-full h-full px-3 py-1 border-gray-300 rounded studio"
			:style="{ 'font-size': fontsize + 'px' }" style="overflow: scroll; height:22vh;"
			placeholder="enter new text here" v-model="new_text_unstripped" ref="promptertextarea"></textarea> -->
			<!-- <div class="flex flex-row justify-center w-full" v-if="finished == true">
			<div><button @click="increaseLevel()" class="border-sky-600 bg-sky-700 hover:bg-sky-600 dropbtn">Increase
					Difficulty</button></div>
		</div> -->
		</div>
	</div>
</template>

<script>
import { getIdToken } from "firebase/auth";

export default {
	inheritAttrs: false,
	name: "Studio",
	data: () => {
		return {
			new_text_unstripped: "",
			language_name: "",
			title: "",
			latest_text: "",
			phrasechoicesArray: [],
			substringindex: null,
			finished: true, // make this false to hide the Increase Difficulty button until one level completed
			spaced_by: "",
			associations: null,
			parsedAssociations: [], // array of objects that each indicates which range of characters should be highlighted within a given range of milliseconds
			substringArray: [], // array of objects that each includes a substring of the displayed text, with the index of the substring's starting character
			startslice: 0, // helper variable for latest_text_slices function, never accessed outside of that function
			endslice: 0, // helper variable for latest_text_slices function, never accessed outside of that function
			i: 0, // helper variable for latest_text_slices function, never accessed outside of that function
			watchSubstringArray: 0, // helper for rerendering DOM
			watchSubstringIndex: 0,
			substringArrayUpdatingStill: false,
		};
	},
	computed: {
		new_text() {
			if (this.spaced_by != "") {
				let stripped = this.new_text_unstripped.replace(
					this.regexwithmultiplespacedby,
					this.spaced_by
				);
				return stripped;
			} else if (this.spaced_by == "") {
				return this.new_text_unstripped;
			}
		},

		scribingclean() {
			if (this.$store.state.audioDuration < this.studying) {
				return this.$store.state.audioDuration;
			} else {
				return this.studying;
			}
		},

		regexwithspacedby() {
			return new RegExp(`${this.escapeRegex(this.spaced_by)}|(\n)`);
		},

		regexwithmultiplespacedby() {
			return new RegExp(`${this.escapeRegex(this.spaced_by)}+`, "ug");
		},
	},
	props: {
		// ID of associated audio file
		audio_id: {
			default: "",
		},
		studying: {
			default: 200,
		},
		newPhrasescounter: {
			default: 0,
		},
		fontsize: { default: 12 },

		// ID of interpretation displayed in this Editor
		interpretation_id: { default: "" },

		// whether the interpretation is owned "owner", editable "editor", or only viewable "viewer" by the logged-in user
		interpretationStatus: { default: "" },
	},
	watch: {
		interpretationStatus: function () {
			this.fetchNewInterpretation();
		},

		scribingclean: function () {
			this.fetchNewInterpretation();
		},

		new_text: function () {
			if (this.substringArray.length >= 1) {
				if (this.new_text == this.substringArray[this.substringindex].text) {
					this.new_text_unstripped = "";
					this.$emit("increasePhrasesCounter");
				}
			}
		},

		watchSubstringArray: function () {
			this.substringArrayUpdatingStill = false;
			if (
				this.substringArray.length > 0 &&
				this.$store.state.audioDuration > 0
			) {
				this.substringindex = 0;
				this.watchSubstringIndex++;
			}
		},

		"$store.state.audioDuration": function () {
			if (
				this.substringArray.length > 0 &&
				this.$store.state.audioDuration > 0
			) {
				this.substringindex = 0;
				this.watchSubstringIndex++;
			}
		},

		newPhrasescounter: function () {
			if (
				this.substringArray.length > 0 &&
				this.$store.state.audioDuration > 0
			) {
				if (this.substringindex < this.substringArray.length - 1) {
					this.substringindex++;
					this.watchSubstringIndex++;
				} else if (this.substringindex == this.substringArray.length - 1) {
					this.substringindex = 0;
					this.finished = true;
					this.watchSubstringIndex++;
				}
				this.new_text_unstripped = "";
			}
		},

		watchSubstringIndex: function () {
			//if the audio player has loaded and the text has been divided into substrings already, then focus on the textbox
			if (
				this.$store.state.audioDuration > 0 &&
				this.substringArray.length > 0
			) {
				// console.log(this.substringArray[this.substringindex].text);
				this.phrasechoicesArray = [];
				//populate answers array



				this.phrasechoicesArray = [this.substringArray[this.substringindex].text]
				let tempSubstringArray = [...this.substringArray]
				tempSubstringArray.splice(this.substringindex, 1)

				while (this.phrasechoicesArray.length < 4 && tempSubstringArray.length > 0) {
					let randomIndex = Math.floor(Math.random() * this.substringArray.length)
					let falseAnswer = this.substringArray[randomIndex].text

					if (!this.phrasechoicesArray.includes(falseAnswer)) { this.phrasechoicesArray.push(falseAnswer) }
					tempSubstringArray.splice(randomIndex, 1)
				}

				this.$store.commit(
					"updateStartTimePrompter",
					this.substringArray[this.substringindex].starttime / 100
				);
				this.$store.commit(
					"updateEndTimePrompter",
					this.substringArray[this.substringindex].endtime / 100
				);

				this.$store.commit("forceRegionRerender");

				// randomize answers array
				for (let j = this.phrasechoicesArray.length - 1; j > 0; j--) {
					var h = Math.floor(Math.random() * (j + 1));
					var temp = this.phrasechoicesArray[j];
					this.phrasechoicesArray[j] = this.phrasechoicesArray[h];
					this.phrasechoicesArray[h] = temp;
				}

				// this.$refs.promptertextarea.focus();
			}
		},
	},
	methods: {
		toggleOptions() {
			this.$store.commit("toggleOptions")
		},
		increaseLevel() {
			this.$emit("increaseDifficulty")
		},

		chooseanswer(answer) {
			this.new_text_unstripped = answer;
			// if (
			// 	this.interpretationStatus == "owner" ||
			// 	this.interpretationStatus == "editor"
			// ) {
			// 	this.new_text_unstripped = answer;
			// }
		},

		escapeRegex: function (string) {
			return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
		},

		async fetchNewInterpretation() {
			if (this.$store.state.user) {
				// REFRESH ID TOKEN FIRST AND WAIT FOR IT
				await getIdToken(this.$store.state.user)
					.then((idToken) => {
						this.$store.commit("SetIdToken", idToken);
						// console.log(this.$store.state.idToken)
					})
					.catch((error) => {
						// An error happened.
						console.log("Oops. " + error.code + ": " + error.message);
					});
			}

			this.new_text_unstripped = "";
			this.phrasechoicesArray = [];
			this.substringindex = null;
			this.watchSubstringIndex++;
			this.associations = null;
			this.parsedAssociations = [];
			this.substringArray = [];

			fetch(
				process.env.VUE_APP_api_URL +
				"interpretations/" +
				this.interpretation_id +
				"/audio/" +
				this.audio_id +
				"/" +
				this.interpretationStatus +
				"/",
				{
					method: "GET",

					headers: {
						"Content-Type": "application/json",

						Authorization: this.$store.state.idToken,
					},
				}
			)
				.then((response) => response.json())
				.then((data) => {
					this.title = data.interpretation.title;
					this.language_name = data.interpretation.language_name;
					this.latest_text = data.interpretation.latest_text;
					this.spaced_by = data.interpretation.spaced_by;
				})

				// access the information about what to highlight, and when, for the interpretation that is to be displayed
				.then(() => {
					// console.log(this.scribingclean)

					fetch(
						process.env.VUE_APP_api_URL +
						"content/" +
						this.audio_id +
						"/" +
						this.interpretation_id +
						"/" +
						this.scribingclean + // FLAG TIME DECISION
						"/", // timestep is 200 hundredths of seconds
						{
							method: "GET",
							headers: {
								"Content-Type": "application/json",

								Authorization: this.$store.state.idToken,
							},
						}
					)
						.then((response) => response.json())
						.then((data) => {
							this.associations = data.associations;
						})
						.then(() => this.parsed_associations()) // turn the highlighting information from backend into something usable
						.then(() => {
							this.latest_text_slices(); // split up the displayed text into substrings to be highlighted whenever necessary
						})
						.catch((error) => console.error("Error:", error));
				})

				.catch((error) => console.error("Error:", error));
		},

		parsed_associations: function () {
			this.parsedAssociations.length = 0;
			let parsedAssociations2 = []
			if (this.associations) {
				for (let i = 0; i < Object.keys(this.associations).length; i++) {
					let startTime = Object.keys(this.associations)[i].split("-")[0];
					let endTime = Object.keys(this.associations)[i].split("-")[1];
					let intervalsCount = Object.values(this.associations)[i].length;

					for (let j = 0; j < intervalsCount; j++) {
						let startCharacter = Object.values(this.associations)[i][j].split(
							"-"
						)[0];
						let endCharacter = Object.values(this.associations)[i][j].split(
							"-"
						)[1];

						let associationsObject = {};
						associationsObject.startTime = startTime;
						associationsObject.endTime = endTime;

						associationsObject.startCharacter = startCharacter;
						associationsObject.endCharacter = Number.parseInt(endCharacter) + 1;
						parsedAssociations2.push(associationsObject);
					}
				}
			}

			parsedAssociations2.sort((a, b) => b.startCharacter - a.startCharacter);
			// console.log(...parsedAssociations2)
			parsedAssociations2.forEach((parsed2) => {
				this.parsedAssociations.forEach((parsed1) => {
					if (parsed2.endCharacter >= parsed1.endCharacter) {
						parsed2.startTime = Math.min(parsed2.startTime, parsed1.startTime)
						parsed2.endTime = Math.max(parsed2.endTime, parsed1.endTime)
					}

				})
				this.parsedAssociations.unshift(parsed2)
			}
			)
			// console.log(this.parsedAssociations)
		},

		latest_text_slices() {
			this.substringArray = [];
			if (this.parsedAssociations.length >= 1) {
				// console.log("here")
				this.i = 0;
				while (this.i + 1 <= this.parsedAssociations.length) {
					let slice = {};
					this.startslice = this.parsedAssociations[this.i].startCharacter;
					this.endslice = this.parsedAssociations[this.i].endCharacter;
					slice.starttime = this.parsedAssociations[this.i].startTime;
					slice.endtime = this.parsedAssociations[this.i].endTime;
					slice.text = this.latest_text.substring(
						this.startslice,
						this.endslice
					);
					slice.startingcharacter = this.startslice;
					this.substringArray.push(slice);
					this.i++;
				}
				// console.log(this.substringArray)
				let indicesToDelete = [];
				// delete the ones whose timestamps completely surround another one
				let y = 0;
				while (y < this.substringArray.length) {
					// this is the one that we are checking against
					let checkExteriorStart = Number(parseFloat(this.substringArray[y].starttime).toFixed(2))
					let checkExteriorEnd = Number(parseFloat(this.substringArray[y].endtime).toFixed(2))
					let z = 0;
					while (z < this.substringArray.length) {
						let checkInteriorStart = Number(parseFloat(this.substringArray[z].starttime).toFixed(2))
						let checkInteriorEnd = Number(parseFloat(this.substringArray[z].endtime).toFixed(2))

						if (
							// if this one is exterior to the other one, then delete it
							y != z &&
							((checkInteriorEnd >= checkExteriorEnd &&
								checkInteriorStart <= checkExteriorStart) ||
								(checkInteriorEnd - checkInteriorStart >=
									checkExteriorEnd - checkExteriorStart &&
									((checkInteriorStart < checkExteriorStart &&
										checkInteriorEnd > checkExteriorStart &&
										checkInteriorEnd < checkExteriorEnd) ||
										(checkInteriorStart > checkExteriorStart &&
											checkInteriorStart < checkExteriorEnd &&
											checkInteriorEnd > checkExteriorEnd))))
						) {
							// console.log(this.substringArray[y])
							// 	console.log(this.substringArray[z])
							indicesToDelete.push(z);
							// console.log("deleting second of above")
						} else if (
							// if this one is interior to the other one, then break this while loop, delete the other one and do not index the other one's loop
							y != z &&
							(checkInteriorEnd <= checkExteriorEnd &&
								checkInteriorStart >= checkExteriorStart)
						) {
							// delete exterior one
							// console.log(this.substringArray[y])
							// 	console.log(this.substringArray[z])
							// console.log("deleting first of above")
							indicesToDelete.push(y);
							break;
						}
						// 		// else if (
						// 		// 	// if this one is smaller than the other one and offset with it
						// 		// 	checkInteriorEnd - checkInteriorStart <=
						// 		// 			checkExteriorEnd - checkExteriorStart &&
						// 		// 			((checkInteriorStart < checkExteriorStart &&
						// 		// 				checkInteriorEnd > checkExteriorStart &&
						// 		// 				checkInteriorEnd < checkExteriorEnd) ||
						// 		// 				(checkInteriorStart > checkExteriorStart &&
						// 		// 					checkInteriorStart < checkExteriorEnd &&
						// 		// 					checkInteriorEnd > checkExteriorEnd))
						// 		// ) {

						// 		// }
						// 		 // no match; nothing gets deleted; index inner while loop
						z++;
					}
					y++;
				}
				// console.log(indicesToDelete)
				indicesToDelete = [...new Set(indicesToDelete)];
				// console.log(indicesToDelete)
				for (let t = indicesToDelete.length - 1; t >= 0; t--) {
					this.substringArray.splice(indicesToDelete[t], 1);
				}

				// console.log(this.substringArray)
			}
			// else if (this.parsedAssociations.length == 1) {

			// 	let slice = {};
			// 	slice.text = this.latest_text;
			// 	slice.startingcharacter = 0;
			// 	slice.starttime = 0;
			// 	slice.endtime = this.$store.state.audioDuration;
			// 	this.substringArray.push(slice);
			// }

			if (this.substringArrayUpdatingStill == false) {
				this.substringArrayUpdatingStill = true;
				setTimeout(() => this.watchSubstringArray++, 600);
			}
		},
	},

	mounted() {
		this.parsedAssociations.length = 0;
		if (this.interpretationStatus) {
			this.fetchNewInterpretation();
		}
	},
};
</script>


<style scoped>
.studio {
	-ms-overflow-style: none;
	/* for Internet Explorer, Edge */
	scrollbar-width: none;
	/* for Firefox */
	overflow-y: scroll;
}



.studio::-webkit-scrollbar {
	display: none;
	/* for Chrome, Safari, and Opera */
}


.beautiful {
	font-family: 'Lora', serif;
	/* Quicksand, Lora, Roboto Slab */
	font-size: 1.2em;
	/* color: #333; */
	padding-left: 1.4vw;
	padding-right: 1.4vw;
	/* padding-top: 20px; */
	padding-bottom: 40px;
	margin-right: 20px;
	/* margin-left: 20px; */
	margin-bottom: 20px;
	margin-top: 10px;
	/* background-color: #f9f9f9; */
	/* font-style: italic; */
	border-radius: 16px;
}


.clear {
	font-weight: 600;
	padding-left: 15px;
}

.clear:hover {
	padding-left: 15px;
}

.clear:active {
	transform: translateY(.3px);
}
</style>